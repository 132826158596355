import React, { useEffect, useState } from 'react'
import isEqual from 'lodash/isEqual'
import moment from 'moment'
import union from 'lodash/union'
import { Alert } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'

import RadioButton from '../common/RadioButton'
import { Case_cases_patient, Case_cases_patient_cases } from '../../hasura/graphQlQueries/types/Case'
import { consultationsSelector, ConsultationsState, fetchConsultationPdfAction } from '../../hasura/slices/consultations'
import { pluralize } from '../../lib/helpers'

// @ts-ignore
import { usePrevious } from '../../hooks/usePrevious'

// @ts-ignore
import rightArrow from '../../lib/images/right-arrow.svg'
// @ts-ignore
import report from '../../lib/images/report.svg'
// @ts-ignore
import dangerIcon from '../../lib/images/danger-blue.svg'

interface Props {
  otherCases: [string, number][]
  setOtherCases: (otherCases: [string, number][]) => void
  setDisplayIncludeOtherCasesAlert: (displayIncludeOtherCasesAlert: { show: boolean; field: string }) => void
  patient: Case_cases_patient
  data: Case_cases_patient_cases[]
  caseCreatedAt?: any | null
  displayDicomStandardHelpLink: boolean
}

const MAX_DAY_DIFF_FOR_RECENT_CASE_CONDITION = 14

export const caseDescription = (p: Case_cases_patient_cases) =>
  `${pluralize('image', p.medical_images_aggregate.aggregate?.count)}${p.consultations.length > 0 ? ' and report' : ''} from ${moment(
    p.created_at
  ).format('M/DD/YY')} (${p.id})`

export default function PastCases(props: Props) {
  const dispatch = useDispatch()
  const [displayPastCases, setDisplayPastCases] = useState(false)

  const { presignedCaseImageUrls }: ConsultationsState = useSelector(consultationsSelector)

  const previousData = usePrevious(props.data)

  useEffect(() => {
    if (props.otherCases.length > 0) setDisplayPastCases(true)
  }, [props.otherCases])

  useEffect(() => {
    if (!props.data.length || !props.caseCreatedAt || isEqual(previousData, props.data)) return

    const hasRecentCases = props.data.some((c) => {
      return Math.abs(moment(c.created_at).diff(moment(props.caseCreatedAt), 'days')) <= MAX_DAY_DIFF_FOR_RECENT_CASE_CONDITION
    })

    if (hasRecentCases) {
      setDisplayPastCases(true)
    }
  }, [props.data, props.caseCreatedAt])

  return (
    <div>
      <div onClick={() => setDisplayPastCases(!displayPastCases)} className="d-flex align-items-center pointer">
        <p className="text-s bold m-0">Include other cases</p>

        <img style={{ transform: displayPastCases ? 'rotate(90deg)' : '' }} className="transition-f ml-1 icon-xxxs" src={rightArrow} />
      </div>

      {displayPastCases && (
        <div>
          <p className="text-s mb-1">Select cases to combine or compare. Cases with similar upload times included by default.</p>

          {props.displayDicomStandardHelpLink && (
            <Alert color="secondary" className="text-s width-fit-content pl-2 pr-3 py-2 flex-center mt-2">
              <img className="icon-xxxs mr-1" src={dangerIcon} />
              Why aren't my images grouped together?{' '}
              <span
                onClick={() =>
                  window.open('https://help.radimal.ai/en/articles/7178435-why-aren-t-my-images-being-grouped-together', '_blank')
                }
                className="underline mx-1 pointer"
              >
                Click here
              </span>
              to learn more.
            </Alert>
          )}

          {props.data.map((p, idx) => {
            const selected = props.otherCases.find((o) => o[1] === p.id)
            const combineSelected = Boolean(selected?.[0] === 'combine')
            const compareSelected = Boolean(selected?.[0] === 'compare')
            const without = props.otherCases.filter((o) => o[1] !== p.id)

            return (
              <div className="mb-3" key={idx}>
                <div className="d-flex align-items-end">
                  <div className="mr-1">
                    {p.medical_images
                      .filter((m) => m.aws_s3_url)
                      .map((m) => presignedCaseImageUrls.find((p) => p.includes(m.aws_s3_url!)))
                      .map((url, idx) => (
                        <img className="mr-1 thumbnail" key={idx} src={url} />
                      ))}
                  </div>

                  {p.consultations.length > 0 && (
                    <img
                      onClick={() => dispatch(fetchConsultationPdfAction(p.consultations[0]!.s3_url!, props.patient, undefined, true))}
                      className="pointer icon-l"
                      src={report}
                    />
                  )}
                </div>

                <p className="text-xs text--gray8 mb-0">{caseDescription(p)}</p>

                <div>
                  <RadioButton
                    onClick={() => {
                      const value: [string, number][] = combineSelected ? without : union(without, [['combine', p.id]])
                      props.setOtherCases(value)
                      if (value.length) props.setDisplayIncludeOtherCasesAlert({ show: false, field: 'notes' })
                    }}
                    checked={combineSelected}
                    label="Combine"
                  />

                  <RadioButton
                    onClick={() => {
                      const value: [string, number][] = compareSelected ? without : union(without, [['compare', p.id]])
                      props.setOtherCases(value)
                      if (value.length) props.setDisplayIncludeOtherCasesAlert({ show: false, field: 'notes' })
                    }}
                    checked={compareSelected}
                    label="Compare"
                  />
                </div>
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}
