import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Button } from 'reactstrap'

import callFlask from '../../hasura/callFlask'
import { INTACT, NEUTERED } from '../request-consult/config'
import { Input } from '../common/Input'
import { User_users } from '../../hasura/graphQlQueries/types/User'
import { getVetspirePimsOrganization } from '../../lib/userHelpers'
import { speciesFor } from '../../lib/helpers'

export interface VetspirePatient {
  id: string
  birthDate: string
  breed: string
  name: string
  neutered: boolean
  sex: 'FEMALE' | 'MALE' | 'UNKNOWN'
  species: string
  preferredWeightUnit: string
  latestWeight: {
    unit?: string
    value: number
  }
}

const mapVetspirePatientValues: any = (patient: VetspirePatient) => {
  const weightUnit = patient.latestWeight.unit || patient.preferredWeightUnit || 'lb'
  return {
    id: patient.id,
    display_name: patient.name,
    birthdate: moment(patient.birthDate).format('MM/DD/YYYY'),
    breed: patient.breed,
    neutered: patient.neutered ? NEUTERED : INTACT,
    gender: patient.sex === 'UNKNOWN' ? undefined : patient.sex.toLowerCase(),
    species: speciesFor(patient.species),
    weight: `${patient.latestWeight.value}${weightUnit ? ` ${weightUnit}` : ''}`,
  }
}

// // 'Lemon' patient in Radimal Vetspire sandbox
// const TEST_VETSPIRE_PATIENT_ID = '39112'

interface Props {
  user: User_users
  updatePatient: (patient: VetspirePatient) => void
}

enum Status {
  Success = 'Success',
  Failure = 'Failure',
}

export default function PimsPatientSearch(props: Props) {
  const [searchValue, setSearchValue] = React.useState()
  const [status, setStatus] = useState<Status | undefined>()

  const vetspire = getVetspirePimsOrganization(props.user)

  if (!vetspire) return null

  useEffect(() => {
    setStatus(undefined)
  }, [searchValue])

  const search = async () => {
    const result: { success: number; patient: VetspirePatient } = await callFlask(
      `/integrations/vetspire/patient?patient_id=${searchValue}&organization_id=${props.user.organization.id}`
    )
    if (result.success) {
      props.updatePatient(mapVetspirePatientValues(result.patient))
      setStatus(Status.Success)
    } else {
      setStatus(Status.Failure)
    }
  }

  const isSuccessful = status === Status.Success
  return (
    <div className="mb-2">
      <div className="d-flex align-items-end">
        <div>
          <p className="text-s m-0 bold">Import from Vetspire</p>
          <Input value={searchValue} onChange={(e: any) => setSearchValue(e.target.value)} placeholder="Patient ID" type="text" />
        </div>

        <Button onClick={search} disabled={!searchValue || status !== undefined} className="ml-2 width-100px" color="success">
          Import
        </Button>
      </div>

      <p className={`m-0 text-xs bold ${status ? '' : 'hidden'} ${isSuccessful ? 'text--success' : 'text-danger'}`}>
        {isSuccessful ? 'Patient succesfully imported.' : 'Patient not found.'}
      </p>
    </div>
  )
}
