import styled from 'styled-components'

import { media } from '../../lib/cssHelpers'

export default styled.div`
  display: flex;
  ${media.phone`
    display:block;
  `};
`
