import React, { useState } from 'react'
import without from 'lodash/without'
import { FormikProps } from 'formik'

import { Alert } from 'reactstrap'
import { CATSCAN_REGION_OPTIONS } from '../request-consult/config'
import { Case_cases, Case_cases_patient_cases } from '../../hasura/graphQlQueries/types/Case'
import { CatscanRegions } from './config'
import { FormikRadioButton } from '../common/FormikRadioButton'
import { Modality } from '../../lib/modalityHelpers'

// @ts-ignore
import rightArrow from '../../lib/images/right-arrow.svg'
// @ts-ignore
import dangerIcon from '../../lib/images/danger-blue.svg'
// @ts-ignore
import checkmarkIcon from '../../lib/images/checkmark-green.svg'
// @ts-ignore
import rejectIcon from '../../lib/images/exit-red.svg'

interface Props {
  extraImageCost?: any[]
  formik: FormikProps<any>
  modality: Modality
  pastCases: Case_cases_patient_cases[]
  presignedUrls: string[]
  radimalCase: Case_cases
  region?: CatscanRegions
  setUnselectedImageUrls: (urls: string[]) => void
  unselectedImageUrls: string[]
}

export default function Images(props: Props) {
  const [displaySelectedImages, setDisplaySelectedImages] = useState(false)

  const {
    extraImageCost,
    formik,
    modality,
    pastCases,
    presignedUrls,
    radimalCase,
    region,
    setUnselectedImageUrls,
    unselectedImageUrls,
  } = props

  const selectedImagesCount = presignedUrls.length - unselectedImageUrls.length

  const extraImageAlert = extraImageCost && (
    <Alert color="secondary" className="text-s width-fit-content pl-2 pr-3 py-2 flex-center mt-2">
      <img className="icon-xxxs mr-1" src={dangerIcon} />
      Additional cost ${extraImageCost[0]} for{' '}
      {modality === Modality.Catscan ? `${region} CT cases.` : `cases with more than ${extraImageCost[1]}.`}
    </Alert>
  )

  const imageType = modality === Modality.Xray ? 'images' : 'images / cineloops'

  return (
    <div>
      {modality === Modality.Catscan && (
        <div>
          <FormikRadioButton
            containerClassName="mt-2"
            label="Region(s)"
            subLabel="Consults are billed based on the number of regions."
            name="regions"
            options={CATSCAN_REGION_OPTIONS}
          />
          {extraImageAlert}
        </div>
      )}

      {modality !== Modality.Catscan && (
        <div className={pastCases.length > 0 ? 'mb-3' : ''}>
          <div onClick={() => setDisplaySelectedImages(!displaySelectedImages)} className="d-flex align-items-center pointer">
            <p className="text-s bold m-0">
              Reduce {imageType} ({selectedImagesCount})
            </p>
            <img
              style={{ transform: displaySelectedImages ? 'rotate(90deg)' : '' }}
              className="transition-f ml-1 icon-xxxs"
              src={rightArrow}
            />
          </div>

          {displaySelectedImages && (
            <div>
              <p className="text-s mb-1">All {imageType} included by default. If you don't something reviewed, remove it below.</p>

              {presignedUrls.length > 0 && (
                <div className="d-flex gap-15px flex-wrap align-items-end max-width-900px max-height-400px overflow-scroll py-3">
                  {presignedUrls.map((url, idx) => {
                    const isSelected = unselectedImageUrls.includes(url)
                    const disabled = !isSelected && selectedImagesCount === 1

                    const isVideo = radimalCase.medical_images.find((image) => url.includes(image.aws_s3_url || ''))?.is_video

                    return (
                      <div
                        onClick={() => {
                          const updated = isSelected ? without(unselectedImageUrls, url) : unselectedImageUrls.concat(url)
                          setUnselectedImageUrls(updated)
                          formik.setFieldValue('imagesCount', radimalCase.medical_images.length - updated.length)
                        }}
                        key={idx}
                        className="width-fit-content pointer height-fit-content position-relative"
                      >
                        <img
                          className={`max-width-300px thumbnail transition-f ${disabled ? 'pe-none' : ''} ${
                            isSelected ? 'opacity-10' : ''
                          }`}
                          src={url}
                        />

                        {isVideo && (
                          <p
                            style={{ bottom: '-15px' }}
                            className={`${
                              isSelected ? 'opacity-10' : ''
                            } position-absolute ls-sm text-xxs bold mb-0 right-0 text--primary`}
                          >
                            CINELOOP
                          </p>
                        )}

                        <img
                          style={{ bottom: '3px', right: '3px', padding: '2px' }}
                          className="icon-s position-absolute bg--white rounded-circle border border-dark"
                          src={isSelected ? rejectIcon : checkmarkIcon}
                        />
                      </div>
                    )
                  })}
                </div>
              )}

              <div className="d-flex align-items-center justify-content-start">
                <p className="mr-1 mb-0 text-s">Waiting for more {imageType}.</p>
                <div className="pulse-circle" />
              </div>
            </div>
          )}

          {/* {formik.errors.imagesCount && <p className="mt-1 error">{formik.errors.imagesCount}</p>} */}

          {extraImageAlert}
        </div>
      )}
    </div>
  )
}
